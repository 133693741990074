@import '../../assets/sass/theme.scss';

html {
  height: 100%;
}

body {
  height: 100%;
  color: #777;
}

::selection {
  background: lighten($complementary-color, 33%);
}

::-moz-selection {
  background: lighten($complementary-color, 33%);
}

.app {
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-image: linear-gradient(white, rgba($primary-color, 0.065));

  .app-content {
    width: 90%;
    padding-top: 2em;
    flex: 1;
  }

  .app-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    background: radial-gradient(
      circle,
      $primary-color,
      darken($primary-color, 14%) calc(1rem + 64vmin)
    );
  }
}
