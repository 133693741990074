@import './utils.scss';

@include keyframes(fadein) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include keyframes(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
