@import '../../../assets/sass/animations';
@import '../../../assets/sass/theme';

@mixin fillBackground {
  color: white;
  background: linear-gradient(
    to right,
    darken($primary-color, 33%) 50%,
    darken($primary-color, 33%) 50%,
    white 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none;
}

.app-header {
  @include animation('fadein 1s');
  display: flex;
  font-size: calc(1rem + 2vmin);
  color: white;
  width: 100%;

  .login-button-container {
    svg {
      @include transition(color 1s);
    }

    &:hover {
      p {
        background-position: 0 100%;
      }

      svg {
        color: darken($primary-color, 33%);
      }
    }

    a,
    p {
      cursor: pointer;
      display: inline-block;
      user-select: none;
    }

    a {
      padding-left: 0;
    }

    p {
      @include fillBackground();
      font-size: calc(1rem + 2vmin);
      margin: 0;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

.header-container {
  background: $primary-color;
  background: linear-gradient(
    222deg,
    lighten($primary-color, 14%) 0%,
    $primary-color 100%
  );
}

.header-home-icon {
  background: none;
  transition: color 0.6s linear;
  &:hover {
    color: darken(white, 10%);
  }
}

.header-profile-picture {
  max-width: 2em;
  border: 2px solid white;
}

.pointer {
  cursor: pointer;
}

.button-nav-link {
  text-transform: initial !important;
}
